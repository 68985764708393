<template>
	<PageLayout :title="$t('profile-info.title')" large-width>
		<ErrorCard :error="getUserError"/>
		<div v-if="getUserError === null" class="cashier-box profile-info">
			<div class="form-wrapper">
				<div class="profile-pic-section">
					<h3 class="title">
						{{ $t('profile-info.profile-picture') }}
					</h3>
					<div class="picture-wrapper">
						<img v-if="!pickerShown" :src="userProfilePictureUrl" alt="user-avatar" class="picture" />
						<avatar-cropper
							v-model="shouldShowPicker"
							@shown="pickerShown = true"
							@cancel="pickerShown = false"
							@completed="pickerShown = false"
							@uploaded="handlePictureUploaded"
						/>
					</div>
					<div v-if="!pickerShown" class="upload-remove-buttons">
						<CButton tertiary condensed @click.prevent="onPictureUpload">
							{{ $t('profile-info.change-picture') }}
						</CButton>
						<CButton
							v-if="userProfilePictureExists === true"
							secondary
							condensed
							:loading="removeProfilePicture.loading"
							@click.prevent="removeUserProfilePicture"
						>
							{{ $t('profile-info.remove-picture') }}
						</CButton>
						<FormError :error="removeProfilePicture.error" />
					</div>
					<p class="instructions">
						{{ $t('profile-info.picture-instructions') }}
					</p>
				</div>
				<div class="basic-info-section">
					<CForm @submit.prevent="submitForm">
						<h3 class="title">
							{{ $t('profile-info.info') }}
						</h3>
						<div class="inputs">
							<MaterialInput
								v-model="form.fullname.value"
								name="fullname"
								:error="form.fullname.error"
								type="text"
								:label="$t('registration.full-name')"
							/>
							<MaterialInput
								v-model="form.email.value"
								name="email"
								:error="form.email.error"
								type="email"
								:label="$t('profile-info.mail.title')"
							/>

							<MaterialPhoneInput
								:default-country="country"
								:mobilenumber="form.mobilenumber.value"
								:error="form.mobilenumber.error"
								:label="$t('profile-info.phone.title')"
								@set-mobile-number="setMobileNumber"
							/>

							<MaterialSelect
								id="country"
								v-model="form.country.value"
								:label="$t('registration.country')"
								:error="form.country.error"
							>
								<option v-for="(country, countryIndex) in getCountries" :key="countryIndex" :value="country.isoCode">
									{{ country.name }}
								</option>
							</MaterialSelect>
						</div>

						<CButton type="submit" primary full-width :loading="loading" :success="showSuccess">
							{{ $t('global.update') }}
						</CButton>

						<div class="change-password change-password--desktop">
							<h3 class="title">
								{{ $t('profile-info.password') }}
							</h3>
							<CButton secondary full-width @click.prevent="goToChangePassword">
								{{ $t('update-password.title') }}
							</CButton>
						</div>

						<FormError :error="error" />
					</CForm>
				</div>

				<div class="change-password change-password--mobile">
					<h3 class="title">
						{{ $t('profile-info.password') }}
					</h3>
					<CButton secondary full-width @click.prevent="goToChangePassword">
						{{ $t('update-password.title') }}
					</CButton>
				</div>
			</div>
		</div>
	</PageLayout>
</template>

<script>
import * as Sentry from '@sentry/vue';
import { mapActions, mapGetters } from 'vuex';
import MaterialInput from '@/components/material-input/MaterialInput';
import CForm from '@/shared/cform/CForm';
import FormError from '@/shared/forms/FormError';
import CButton from '@/shared/cbutton/CButton';
import PageLayout from '@/components/page-layout/PageLayout';
import MaterialSelect from '@/components/material-select/MaterialSelect';
import MaterialPhoneInput from '@/shared/material-phone-input/MaterialPhoneInput';
import AvatarCropper from '@/components/avatar-cropper/AvatarCropper';
import apiClient from '@/api';
import defaultAvatar from '@/assets/icons/default-avatar.svg';
import ErrorCard from '@/shared/error-card/ErrorCard';

export default {
	name: 'ProfileInfo',
	components: {
		MaterialPhoneInput,
		PageLayout,
		CButton,
		FormError,
		CForm,
		MaterialInput,
		MaterialSelect,
		AvatarCropper,
		ErrorCard,
	},
	data() {
		return {
			success: false,
			loading: false,
			error: null,
			form: {
				fullname: {
					value: null,
					error: null,
				},
				email: {
					value: null,
					error: null,
				},
				mobilenumber: {
					value: null,
					error: null,
					isValid: false,
				},
				currentPassword: {
					value: '',
					error: null,
				},
				newPassword: {
					value: null,
					error: null,
				},
				repeatPassword: {
					value: null,
					error: null,
				},
				country: {
					value: null,
					error: null,
				},
			},
			country: null,
			showSuccess: false,
			shouldShowPicker: false,
			pickerShown: false,
			removeProfilePicture: {
				loading: false,
				success: false,
				error: null,
			},
			userProfilePictureExists: null,
		};
	},
	computed: {
		...mapGetters(['getUser', 'getUserError', 'isEmbedded', 'getAuthToken', 'getCountries']),
		userProfilePictureUrl() {
			if (this.userProfilePictureExists) {
				return this.getUser.avatarUrl;
			}
			return defaultAvatar;
		},
	},
	watch: {
		success(value) {
			if (value) {
				this.showSuccess = true;

				setTimeout(() => (this.showSuccess = false), 3500);
			}
		},
		getUser: {
			handler(user) {
				this.updateUserState(user);
			},
			immediate: true,
		},
	},
	beforeMount() {
		this.getUserProfilePicture();
	},
	methods: {
		...mapActions(['updateUser', 'refetchUser']),
		updateUserState(user) {
			this.form.fullname.value = user.name;
			this.form.email.value = user.email;
			this.form.mobilenumber.value = user.mobilenumber;
			this.form.country.value = user.country;
		},
		setMobileNumber(event) {
			this.form.mobilenumber.value = event.number;
			this.form.mobilenumber.isValid = event.isValid;
		},
		goToChangePassword() {
			this.$router.push('/profile/change-password');
		},
		onPictureUpload() {
			this.shouldShowPicker = true;
		},
		async handlePictureUploaded() {
			await this.refetchUser();
			this.userProfilePictureExists = true;
		},
		async getUserProfilePicture() {
			try {
				await apiClient.fetchUserProfilePicture();
				this.userProfilePictureExists = true;
			} catch (e) {
				this.userProfilePictureExists = false;
			}
		},
		async removeUserProfilePicture() {
			try {
				this.removeProfilePicture = {
					loading: true,
					success: false,
					error: null,
				};

				await apiClient.deleteUserProfilePicture();
				await this.refetchUser();
				this.userProfilePictureExists = false;
				this.removeProfilePicture = {
					loading: false,
					success: true,
					error: null,
				};
			} catch (e) {
				this.removeProfilePicture = {
					loading: false,
					success: true,
					error: 'image-delete-failed',
				};
			}
		},
		async submitForm() {
			const { form } = this;
			this.clear();
			if (!form.fullname.value) {
				form.fullname.error = this.$t('profile-info.name.empty-error');
			}
			if (!form.email.value) {
				form.email.error = this.$t('profile-info.mail.empty-error');
			}

			if (!form.mobilenumber.isValid) {
				form.mobilenumber.error = this.$t('profile-info.phone.invalid');
			}

			const hasErrors = Object.entries(form).filter((p) => p[1].error).length !== 0;
			if (!hasErrors) {
				this.loading = true;

				try {
					await this.updateUser({
						fullname: form.fullname.value,
						email: form.email.value,
						mobilenumber: form.mobilenumber.value,
						country: form.country.value,
					});
					this.success = true;
				} catch (e) {
					const { response } = e;

					if (!response) {
            Sentry.captureException(e);
						this.error = e.toString();
            return;
					}

					if (response.data.errors) {
						for (const error of response.data.errors) {
							const { param, msg } = error;
							form[param].error = msg;
						}
					}

					if (response.data.error && typeof response.data.error === 'string') {
						this.error = response.data.error;
					}
				} finally {
					this.loading = false;
				}
			}
		},
		clear() {
			for (const param in this.form) {
				this.form[param].error = '';
			}
			this.error = null;
			this.success = false;
		},
	},
};
</script>

<style scoped lang="scss">
@import 'src/assets/style/_variables';
@import 'src/assets/style/mixin';

.change-password {
	&--desktop {
		margin-top: 3rem;

		@include max-screen($md) {
			display: none;
		}
	}

	&--mobile {
		@include cashier-box-style;
		margin-bottom: $mobile-horizontal-margin;
		padding: $mobile-form-padding;

		.button {
			margin-bottom: 0;
		}

		@include min-screen($md + 1) {
			display: none;
		}
	}
}

.profile-info {
	display: flex;
	flex-direction: column;
	padding: 0;

	@include max-screen($md) {
		background: transparent;
		border: none;
	}

	.title {
		text-align: start;
		margin-top: 0;
	}

	.form-wrapper {
		display: flex;
		flex-direction: row-reverse;

		@include max-screen($md) {
			flex-direction: column;
		}
	}

	.basic-info-section {
		flex: 0 0 50%;
		padding: $form-padding;

		@include max-screen($md) {
			padding: $mobile-form-padding;
			@include cashier-box-style;
			margin-bottom: $mobile-horizontal-margin;
		}

		.inputs {
			// margin collapse workaround
			overflow: hidden;
		}

		.change-password {
			.button {
				margin-top: 0;
			}
		}
	}

	.profile-pic-section {
		flex: 0 0 50%;
		background: #3f404620;
		border-left: 1px solid $shark-black;
		padding: $form-padding;

		@include max-screen($md) {
			padding: $mobile-form-padding;
			@include cashier-box-style;
			margin-bottom: $mobile-horizontal-margin;
		}

		.picture {
			margin-top: 1rem;
			width: 200px;
			height: 200px;
			border-radius: 50%;
		}

		.upload-remove-buttons {
			margin: 1rem auto 0;
			width: 200px;
		}
	}

	.instructions {
		color: $light-gray;
		font-size: $caption;
		text-align: center;
	}
}
</style>
